"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.brandNames = void 0;
exports.brandNames = [
    {
        id: 0,
        title: 'visa',
    },
    {
        id: 1,
        title: 'mastercard',
    },
    {
        id: 2,
        title: 'jcb',
    },
    {
        id: 3,
        title: 'amex',
    },
    {
        id: 4,
        title: 'discover',
    },
    {
        id: 5,
        title: 'diners',
    },
    {
        id: 6,
        title: 'unknown',
    },
];
