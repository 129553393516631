"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputErrorMessages = void 0;
const inputErrorMessages = {
    cardNumber: 'The card number you entered is invalid',
    expiration: 'The expiration year or month is invalid, make sure you do not use a past or present date',
    cvv: 'CVV is invalid',
    nameOnCard: 'Card holder name is invalid',
    streetAddress: 'Billing street address is invalid',
    city: 'City address is invalid',
    state: 'State or region address is invalid',
    country: 'Country address is invalid',
    zipCode: 'Postal/Zip Code entered is invalid',
};
exports.inputErrorMessages = inputErrorMessages;
