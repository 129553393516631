"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggerService = void 0;
const AuthviaResources_1 = require("./AuthviaResources");
class LoggerService {
    constructor(name) {
        this.name = name;
        // Disables debug logs in production
        if (AuthviaResources_1.AuthviaResources.NODE_ENV === 'production') {
            this.logLevel = "production";
        }
        else if (AuthviaResources_1.AuthviaResources.NODE_ENV === 'test') {
            this.logLevel = 'error';
        }
        else {
            this.logLevel = 'development';
        }
    }
    debug(...args) {
        if (this.logLevel === 'development') {
            console.debug(this.name, ...args);
        }
    }
    log(...args) {
        if (this.logLevel === 'error')
            return;
        console.log(this.name, ...args);
    }
    warn(...args) {
        if (this.logLevel === 'error')
            return;
        console.warn(this.name, ...args);
    }
    error(...args) {
        console.error(this.name, ...args);
    }
}
exports.LoggerService = LoggerService;
