"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fetch = void 0;
const tslib_1 = require("tslib");
const model_1 = require("../model");
const LoggerService_1 = require("./LoggerService");
const AuthviaResources_1 = require("./AuthviaResources");
class Fetch {
    constructor() {
        this.host = AuthviaResources_1.AuthviaResources.API;
        this.logger = new LoggerService_1.LoggerService(this.constructor.name);
    }
    /**
     * Sends a POST request to the specified path with the given payload and optional headers.
     *
     * @template ResponseType - Represents the type of the API response.
     * @param path - The API endpoint path relative to the base host.
     * @param payload - The data to send in the body of the POST request. Can be a JSON object or a string.
     * @param token - (Optional) The bearer token for authorization. If provided, it is included in the `Authorization` header.
     * @param Squelch - (Optional) A flag to indicate if the `Squelch` header should be set to `'true'`. Defaults to `undefined` if not provided.
     * @returns A promise resolving to the response data of type `ResponseType`.
     * @throws Will throw an error if the response indicates a failure, based on the `asError` method.
     */
    post(path, payload, token, Squelch) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.logger.debug("post() request ", { path, payload });
            const headers = {
                "Content-Type": "application/json",
                Authorization: token ? `Bearer ${token}` : undefined,
            };
            if (Squelch)
                headers.Squelch = Squelch ? "true" : "false";
            const response = yield fetch(`https://${this.host}/${path}`, {
                method: "POST",
                body: typeof payload === "string" ? payload : JSON.stringify(payload),
                headers,
            });
            yield this.asError(response);
            return response.json();
        });
    }
    /**
     *
     * @param response
     */
    asError(response) {
        var _a, _b;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!response.ok) {
                this.logger.warn("asError() caught an error.", { response });
                const body = yield response.json();
                let requestId = "unknown";
                if (response.headers) {
                    requestId = response.headers.get("x-request-id") || "unknown";
                }
                // We want to parse the error messages from validation as error since they are sent back from the API as array of strings
                let error;
                if (response.status == 400) {
                    //If there is a reason || field for the error, the front-end will render message based on those.
                    if (body.reason || body.field) {
                        error = new model_1.AVError(body.code || `${response.status}`, body.message, [body]);
                    }
                    else if ((_a = body.errors) === null || _a === void 0 ? void 0 : _a.length) {
                        // check if errors are array based on new response in API
                        const errors = (_b = body.errors) === null || _b === void 0 ? void 0 : _b.map((err) => err.attribute);
                        error = new model_1.AVError(body.code || `${response.status}`, `${body.message || response.statusText}\n ID: ${requestId}`, errors);
                    }
                    else {
                        const errors = body.message;
                        error = new model_1.AVError(body.code || `${response.status}`, `${body.message || response.statusText}\n ID: ${requestId}`, errors);
                    }
                }
                else {
                    // Other errors can be as they were
                    error = new model_1.AVError(body.code || `${response.status}`, `${body.message || response.statusText}\n ID: ${requestId}`);
                }
                throw error;
            }
        });
    }
}
exports.Fetch = Fetch;
