"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AVError = void 0;
/**
 * Authvia Error
 */
class AVError extends Error {
    /**
     * Construct Error With Code
     * @param code
     * @param message
     * @param errors
     */
    constructor(code, message, errors) {
        super(message);
        this.code = code;
        this.errors = errors;
    }
}
exports.AVError = AVError;
