<template>
  <svg :style="{ width: `${size}px`, height: `${size}px` }" :viewbox="viewboxSize">
    <path :fill="fill" :d="path"></path>
  </svg>
</template>

<script>
export default {
  name: 'mdi-icon',
  props: {
    path: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
    fill: {
      type: String,
      required: false,
      default: '#000000',
    },
  },
  computed: {
    viewboxSize() {
      return `0 0 ${this.size} ${this.size}`;
    },
  },
};
</script>
