"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorMessages = void 0;
const errorMessages = {
    invalidToken: 'Invalid token - Your session may have expired, please try refreshing the page or logging in again',
    failedPCI: 'Invalid scopes - Please try refreshing the page or logging in again',
    failedPKCE: 'Invalid PKCE - Please try refreshing the page or logging in again',
    serverIrresponsive: 'It looks like the server is not responding to your request. Please wait a short time and try again',
    unauthorized: 'Something has gone wrong. It looks like the access to execute this has been removed or timed out, please try refreshing the page and trying again',
};
exports.errorMessages = errorMessages;
