"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserAndToken = exports.parse = void 0;
/**
 * Parses a JWT token and returns its payload.
 * @param token - The JWT token string.
 * @returns The parsed payload as a TokenGrants object.
 */
const parse = (token) => {
    const parts = token.split(".");
    const payload = JSON.parse(atob(parts[1]));
    return payload;
};
exports.parse = parse;
/**
 * Extracts the user ID and token from an auth token.
 * @param authToken - The authentication token string.
 * @returns An object containing the user ID and the original auth token.
 * @throws Error if the auth token is missing or invalid.
 */
const getUserAndToken = (authToken) => {
    if (!authToken)
        throw new Error("Auth token is missing");
    const payload = (0, exports.parse)(authToken);
    const { "https://authvia.com/uuid": user } = payload;
    if (!user)
        throw new Error("User ID is missing in the token payload");
    return { user, token: authToken };
};
exports.getUserAndToken = getUserAndToken;
