"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthviaResources = void 0;
/**
 * Takes care of ENV Variables
 */
class AuthviaResources {
}
exports.AuthviaResources = AuthviaResources;
AuthviaResources.CDN = process.env.VUE_APP_CDN_HOST || process.env.VITE_APP_CDN_HOST;
AuthviaResources.API = process.env.VUE_APP_API_HOST || process.env.VITE_APP_API_HOST;
AuthviaResources.NODE_ENV = process.env.NODE_ENV ||
    process.env.VUE_APP_NODE_ENV ||
    process.env.VITE_APP_NODE_ENV;
