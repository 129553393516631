"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.V2PaymentMethodService = void 0;
const tslib_1 = require("tslib");
const utils_1 = require("../utils");
class V2PaymentMethodService {
    constructor() {
        this.logger = new utils_1.LoggerService(this.constructor.name);
        this.fetch = new utils_1.Fetch();
        this.logger.log("init()");
    }
    /**
     * Creates a new Payment Method for the specified user.
     *
     * @param userId - The unique identifier of the user for whom the payment method is being created.
     * @param payload - The details of the payment method to be created, adhering to the `PaymentMethodOptions` interface.
     * @param authToken - The authorization token required to authenticate the request.
     * @param squelch - (Optional) A flag to indicate if the `Squelch` header should be set to `'true'`. Defaults to `undefined` if not provided.
     * @returns A promise resolving to the created `PaymentMethod` object, or `undefined` if no payment method is returned.
     * @throws Will throw an error if the request fails.
     */
    create(userId, payload, authToken, squelch) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const paymentMethod = yield this.fetch.post(`v3/users/${userId}/payment-methods`, payload, authToken, squelch);
                return paymentMethod;
            }
            catch (error) {
                this.logger.error("create() failed", error);
                throw error;
            }
        });
    }
}
exports.V2PaymentMethodService = V2PaymentMethodService;
