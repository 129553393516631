"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateInput = exports.validateFormData = void 0;
const validate_js_1 = require("validate.js");
// http://validatejs.org/
/**
 * Checks whether a number is a Luhn number or not
 * @param number string
 * @returns boolean
 */
function isValidLuhnNumber(number) {
    if (number && number.length) {
        const clean = number.replace(/ /g, '');
        const len = clean.length;
        const parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i--) {
            let d = parseInt(clean.charAt(i));
            if (i % 2 == parity) {
                d *= 2;
            }
            if (d > 9) {
                d -= 9;
            }
            sum += d;
        }
        const valid = sum % 10 == 0;
        return valid;
    }
    else {
        return false;
    }
}
function isValidFutureMonthYear(input) {
    const currentTime = new Date(Date.now());
    const currentYear = currentTime.getFullYear();
    const currentMonth = currentTime.getMonth() + 1; // JS month starts from 0
    const expirations = input.split('/');
    const month = Number.parseInt(expirations[0]);
    let year = Number.parseInt(expirations[1]);
    const lastTwoDigits = currentYear % 100;
    year = currentYear - lastTwoDigits + year;
    // If the year is some times in the future the input is valid, as the month will be in the future as well
    if (Number(year) > currentYear) {
        return true;
        // If the year is in the past its invalid year
    }
    else if (Number(year) < currentYear) {
        return false;
    }
    else {
        // If the year is the same one and the month is current month or previous month, input is invalid
        if (Number(month) < currentMonth) {
            return false;
        }
        // If the month is the next month or not the current month, input is valid
        return true;
    }
}
// Custom Validator for checking Luhn
validate_js_1.validators.isLuhn = function (value) {
    const isValid = isValidLuhnNumber(value);
    if (!isValid) {
        return 'is not properly formatted';
    }
};
validate_js_1.validators.isValidMonthYear = function (value) {
    const isValid = isValidFutureMonthYear(value);
    if (!isValid) {
        return 'is not valid. It must be current or future month and year.';
    }
};
function isValidFullName(value) {
    value = value.trim();
    if (value.length < 3) {
        return false;
    }
    // Check for special characters
    const pattern = /[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/g;
    const result = value.match(pattern);
    if (result && result.length > 0) {
        return false;
    }
    // Check for space separator
    let splittedValue = value.split(' ');
    // Do not support more than 5 part name
    if (splittedValue.length < 2 || splittedValue.length > 5) {
        return false;
    }
    return true;
}
validate_js_1.validators.isValidName = function (value) {
    let isValid = isValidFullName(value);
    if (!isValid) {
        return '^Card holder name is not valid, enter your full name and do not put special characters(#,$,%)';
    }
};
validate_js_1.validators.isValidCVV = function (value) {
    // Also need to show the error in case of blank space
    if (!value.match(/^[0-9]{3,4}$/)) {
        return '^CVV is not valid, it can only be digits (0-9)';
    }
};
validate_js_1.validators.isValidZipCode = function (value) {
    value = value.trim();
    if (!value.match('^[0-9a-zA-Z \-]+$')) {
        return '^Postal/Zip Code entered is invalid';
    }
};
const CARD_MODEL = {
    nameOnCard: {
        presence: {
            allowEmpty: false,
            message: '^Card holder name cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 1,
            maximum: 50,
            message: '^Card holder name must be between 1 and 50 characters',
        },
        isValidName: 'options',
    },
    cardNumber: {
        presence: {
            allowEmpty: false,
            message: '^Card Number cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 13,
            maximum: 19,
        },
        isLuhn: 'options',
    },
    expiration: {
        presence: {
            allowEmpty: false,
            message: '^Expiration cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 5,
            maximum: 5,
        },
        isValidMonthYear: 'options',
    },
    cvv: {
        presence: {
            allowEmpty: false,
            message: '^CVV cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 3,
            maximum: 4,
            message: '^CVV must be 3 or 4 characters long',
        },
        isValidCVV: 'options',
    },
    streetAddress: {
        presence: {
            allowEmpty: false,
            message: '^Street address cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 1,
            maximum: 256,
        },
    },
    city: {
        presence: {
            allowEmpty: false,
            message: '^City cannot be blank',
        },
        type: 'string',
        length: {
            maximum: 128,
            message: '^City can be 128 characters long',
        },
    },
    state: {
        presence: {
            allowEmpty: false,
            message: '^State or region address cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 1,
            maximum: 128,
            message: '^State or region address should be between 1 and 128 characters long',
        },
    },
    country: {
        presence: {
            allowEmpty: false,
            message: '^Country cannot be blank',
        },
        type: 'string',
        length: {
            maximum: 128,
            message: '^Country can be 128 characters long',
        },
    },
    zipCode: {
        presence: {
            allowEmpty: false,
            message: '^Postal/Zip code cannot be blank',
        },
        type: 'string',
        length: {
            minimum: 1,
            maximum: 30,
            message: '^Postal/Zip code should be between 1 and 30 characters long',
        },
        isValidZipCode: 'options',
    },
};
function validateFormData(data) {
    if (!data) {
        throw new Error('Failed to validate data: Data is null or invalid');
    }
    return (0, validate_js_1.validate)(data, CARD_MODEL);
}
exports.validateFormData = validateFormData;
function validateInput(data, inputName) {
    const result = (0, validate_js_1.validate)(data, { [inputName]: CARD_MODEL[inputName] });
    return result;
}
exports.validateInput = validateInput;
